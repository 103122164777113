<template>
    <b-row class="mb-3">
        <b-col cols="12">
            <b-card no-body class="p-3">
                <b-row>
                    <b-col cols="6" class="d-flex flex-column align-items-start justify-content-center">
                        <p class="h5 flex-fill text-primary"><router-link class="text-dark" :to="{ name: 'dash.repairs.complete', params: { id: data.id } }"><fa-icon icon="tools" class="mr-2"/>Repair #{{ data.id }}</router-link></p>
                        <small class="mb-0 text-muted">Scheduled for {{ moment(data.repair_date).format('DD/MM/YYYY') }} @ {{ data.repair_time }}</small>
                    </b-col>
                    <b-col cols="6" class="d-flex align-items-start justify-content-end">
                        <p class="mb-0"><FriendlyDate :direction="'right'" :date="data.created_at"/></p>
                    </b-col>
                </b-row>

                <div class="d-flex flex-column flex-fill">
                    <div><hr></div>
                    <div class="d-none d-md-flex flex-row align-items-end justify-content-end mt-1">
                        <p class="align-self-end mb-0 text-muted"><b-button class="mr-1 mb-0" size="sm" variant="primary" v-b-popover.hover.bottom="'View more information about this order.'"  :to="{name: 'dash.repairs.complete', params: { id: data.id }}"><b-icon icon="tools" />  Complete Repair</b-button></p>
                        <b-button class="mr-1 mb-0" size="sm" variant="secondary" v-b-popover.hover.bottom="'View repair details.'" :to="{ name: 'dash.tickets.view', params: { id: data.ticket.id } }"><b-icon icon="person" /> View Ticket</b-button>
                    </div>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FriendlyDate from '../../../../components/FriendlyDate';
import momentMixin from '../../../../mixins/momentMixin';

export default {
    mixins: [ momentMixin ],
    components: { FriendlyDate },
    props: [ 'data' ]
}

</script>