<template>
    <b-row>

        <b-col cols="12">
            <b-row>
                <b-col cols="12" v-if="customerActivity.isLoading" class="d-flex justify-content-center">
                    <b-spinner variant="secondary"></b-spinner>
                </b-col>
                <b-col v-if="!customerActivity.isLoading && !customerActivity.data.length">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There is nothing in the feed.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                </b-col>
                <b-col v-if="!customerActivity.isLoading && customerActivity.data.length">
                    <component
                        v-for="(feedItem, feedItemInd) in customerActivity.data"
                        :key="`feed-item${feedItemInd}`"
                        :data="feedItem.row"
                        :is="`CustomerActivity${ feedItem.type }`"

                        @replyingToEmail="id => (replyingToEmail = id)"
                    />
                </b-col>
            </b-row>
        </b-col>

        <CustomerActivityEmailModal :replyingToEmail="replyingToEmail" :customer="$props['customer']"/>

    </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import validation from '../../../mixins/validation'
import FriendlyDate from '../../../components/FriendlyDate';

import CustomerActivityOrder from './CustomerActivityComponents/CustomerActivityOrder'
import CustomerActivityTicket from './CustomerActivityComponents/CustomerActivityTicket'
import CustomerActivityRepair from './CustomerActivityComponents/CustomerActivityRepair'
import CustomerActivityNote from './CustomerActivityComponents/CustomerActivityNote'
import CustomerActivityEnquiry from './CustomerActivityComponents/CustomerActivityEnquiry'
import CustomerActivityLiveChat from './CustomerActivityComponents/CustomerActivityLiveChat'
import CustomerActivityDispatchable from './CustomerActivityComponents/CustomerActivityDispatchable'
import CustomerActivityImapEmail from './CustomerActivityComponents/CustomerActivityImapEmail'
import CustomerActivityEmailModal from './CustomerActivityComponents/CustomerActivityEmailModal'
import CustomerActivityCall from './CustomerActivityComponents/CustomerActivityCall'
import CustomerActivityTracking from './CustomerActivityComponents/CustomerActivityTracking'

export default {
    mixins: [ validation ],
    components: {
        CustomerActivityOrder,
        CustomerActivityTicket,
        CustomerActivityRepair,
        CustomerActivityNote,
        CustomerActivityEnquiry,
        CustomerActivityLiveChat,
        CustomerActivityDispatchable,
        CustomerActivityImapEmail,
        CustomerActivityCall,
        CustomerActivityTracking,
        CustomerActivityEmailModal,
        FriendlyDate
    },
    props: [ 'customer' ],
    data: () => ({
        replyingToEmail: null,
    }),
    computed: {
        ...mapGetters('customers', ['customerActivity', 'errors'])
    },
    methods: {
        ...mapActions('customers', ['fetchCustomerActivity']),

        doFetch() {
            this.fetchCustomerActivity(this.customer.id)
        },

    },
    mounted() {
        this.$root.$on('bv::modal::hide', (bvModal, modalId) => {
            if(modalId == 'email-modal') {
                this.replyingToEmail = null
            }
        })  
        this.$root.$on('replyingToEmail', (replyingToEmail) => {
            this.replyingToEmail = replyingToEmail
        })  
    },
    watch: {
        customer(customer) {
            if(customer.id) this.doFetch()
        }
    }
}

</script>