<template>
    <b-row class="mb-3">
        <b-col cols="12">
            <b-card no-body>
                <b-row>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="12" md="7" class="d-flex flex-row align-items-center">
                                <div class="mt-3 ml-3 mb-0 mb-md-3">
                                    <h5 v-if="data.subject"><fa-icon icon="envelope" class="mr-2"/>{{ data.subject }}</h5>
                                    <h5 v-if="!data.subject"><fa-icon icon="envelope" class="mr-2"/>{{ data.type }}</h5>
                                    <small class="text-small text-muted" v-if="data.read_at">Read At: {{ moment(data.read_at).format('DD/MM/YYYY') }}<br></small>
                                    <small class="text-small text-muted" v-if="data.read_count">Read {{ data.read_count == 1 ? 'once' : data.read_count + ' times' }}<br></small>
                                    <small class="text-muted" v-if="data.subject">{{ data.type }}</small>
                                </div>
                            </b-col>
                            <b-col cols="12" md="5" class="d-flex flex-column flex-fill align-items-start justify-content-start align-items-md-end w-100 max-width">
                                <div class="ml-3 mr-3 mt-0 mt-md-3">
                                    <FriendlyDate class="text-right" :date="data.created_at"/>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <div class="m-3"><hr></div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <div class="m-3" v-html="data.content"></div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import FriendlyDate from '../../../../components/FriendlyDate';
import momentMixin from '../../../../mixins/momentMixin'

export default {
    props: [ 'data' ],
    components: { FriendlyDate },
    mixins: [ momentMixin ],
}

</script>