<template>
    <b-row class="mb-3">
        <b-col cols="12">
            <b-card no-body class="p-3">
                <b-row>
                    <b-col cols="6" class="d-flex flex-column align-items-start justify-content-center">
                        <h5 class="mb-0"><fa-icon icon="comments-alt" class="mr-2"/>Enquiry</h5>
                    </b-col>
                    <b-col cols="6" class="d-flex align-items-start justify-content-end">
                        <p class="mb-0"><FriendlyDate :direction="'right'" :date="data.created_at"/></p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                    </b-col>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="12"><hr></b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <p class="mb-0 mb-0 text-primary white-space-pre">{{ data.content }}</p>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import FriendlyDate from '../../../../components/FriendlyDate';
import momentMixin from '../../../../mixins/momentMixin';

export default {
    mixins: [ momentMixin ],
    components: { FriendlyDate },
    props: [ 'data' ],
}

</script>