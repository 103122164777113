<template>
    <b-row class="mb-3">
        <b-col cols="12">
            <b-card no-body>
                <b-row class="p-3">
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="6" class="d-flex flex-column align-items-start justify-content-center">
                                <h5 class="mb-0"><fa-icon icon="pen" class="mr-2"/>Note</h5>
                            </b-col>
                            <b-col cols="6" class="d-flex align-items-center justify-content-end">
                                <p class="mb-0"><FriendlyDate :direction="'right'" :date="data.created_at"/></p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <hr>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="11">
                                <p class="white-space-pre mb-0">{{ data.note }}</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <hr>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" class="d-flex flex-row justify-content-start align-items-center">
                                <p class="note-created mb-0">Left by: {{ data.user.name }}</p>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <template #footer v-if="data.reminder">
                    <b-row class="my-2">
                        <b-col cols="12">
                            <div class="d-flex flex-row">
                                <div class="d-flex flex-column flex-fill">
                                    <h6 class="mb-1">Reminder</h6>
                                    <small class="text-muted">Due: {{ moment(data.reminder.date).format('DD/MM/YYYY') }} @ {{ moment(data.reminder.time, 'HH:mm:ss').format('HH:mm') }}</small>
                                </div>
                                <span>{{ data.reminder.completed_at ? 'Complete' : 'Pending' }}</span>
                            </div>
                            <hr class="mt-2">
                            <span>{{ data.reminder.note }}</span>
                        </b-col>
                    </b-row>
                </template>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import FriendlyDate from '../../../../components/FriendlyDate';
import momentMixin from '../../../../mixins/momentMixin';

export default {
    components: { FriendlyDate },
    mixins: [ momentMixin ],
    props: [ 'data' ],
}

</script>