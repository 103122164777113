<template>
  <b-container fluid>
  
    <b-row class="negate-header-padding">
      <b-col md="8">
        <b-row class="mt-3" :class="{ 'mb-3': !customer.tickets_count }">
          <b-col cols="9" class="flow-column align-items-center justify-content-start">
            <h1 class="font-weight-bold mb-0 text-primary">{{ customer.forename }} {{ customer.surname }}</h1>
            <small class="text-muted" v-if="!isLoading && customer.user && customer.user.name">Handled By: {{ customer.user.name }}</small>
          </b-col>
          <b-col cols="3" class="d-flex flow-row justify-content-end align-item-center">
            <b-button disabled variant="outline-primary d-none d-md-inline" v-if="customer.status">{{ customer.status }}</b-button>
            <b-button variant="outline-primary d-md-none" @click="detailsOpen = !detailsOpen">Details</b-button>
          </b-col>
        </b-row>

        <!-- FEED -->
        <b-row class="mt-3 customer-view-tabs">
            <b-tabs class="w-100" v-model="tabIndex" justified>
              <b-tab class="p-3">
                <template #title>
                  <div class="d-flex flex-nowrap align-items-center justify-content-center">
                    <fa-icon icon="bolt" class="mr-2"/><p class="mb-0">Activity</p>
                  </div>
                </template>
                <CustomerActivity :customer="single"/>
              </b-tab>
              <b-tab class="p-3">
                <template #title>
                  <div class="d-flex flex-nowrap align-items-center justify-content-center">
                    <fa-icon icon="pen" class="mr-2"/><p class="mb-0">Notes</p>
                  </div>
                </template>
                <CustomerNotes :customer="$route.params.id" />
              </b-tab>
              <b-tab class="p-3">
                <template #title>
                  <div class="d-flex flex-nowrap align-items-center justify-content-center">
                    <fa-icon icon="shopping-cart" class="mr-2"/><p class="mb-0">Orders</p>
                  </div>
                </template>
                <CustomerOrders :customer="$route.params.id" />
              </b-tab>
              <b-tab class="p-3" v-if="customer.email">
                <template #title>
                  <div class="d-flex flex-nowrap align-items-center justify-content-center">
                    <fa-icon icon="envelope" class="mr-2"/><p class="mb-0">Correspondence</p>
                  </div>
                </template>
                <CustomerCorrespondence :customer="single" :tab-index="tabIndex"/>
              </b-tab>
              <b-tab>
                <template #title>
                  <div class="d-flex flex-nowrap align-items-center justify-content-center">
                    <fa-icon icon="list" class="mr-2"/><p class="mb-0">Reminders</p>
                  </div>
                </template>
                <div class="px-3 pb-3">
                  <CustomerReminders :customer="$route.params.id" />
                </div>
                
              </b-tab>
              <b-tab class="p-3">
                <template #title>
                  <div class="d-flex flex-nowrap align-items-center justify-content-center">
                    <fa-icon icon="comment-alt-lines" class="mr-2"/><p class="mb-0">Support</p>
                  </div>
                </template>
                <CustomerSupport :customer="$route.params.id" />
              </b-tab>
            </b-tabs>
        </b-row>

      </b-col>

      <b-col md="4" class="right-panel bg-light" :class="{ open : detailsOpen }">
        <b-row>
          <b-col cols="12">
            <b-button variant="outline-primary d-md-none mt-3" @click="detailsOpen = !detailsOpen">Back</b-button>
          </b-col>
          <b-col cols="12" class="mt-md-5">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <b-avatar variant="dark" class="mb-2" size="lg"></b-avatar>
              <b-skeleton-wrapper key="customer-details-skeleton" :loading="isLoading" class="w-100 d-flex flex-column align-items-center justify-content-center">
                <template #loading>
                  <b-skeleton width="25%"></b-skeleton>
                  <b-skeleton width="40%"></b-skeleton>
                  <b-skeleton width="20%"></b-skeleton>
                </template>

                <h3 class="font-weight-bold text-primary mb-0">{{ customer.forename }} {{ customer.surname }}</h3>
                <small class="text-muted mb-2">{{ customer.email }}</small>
                <b-button :to="{name:'dash.customers.update', params: { id: $route.params.id }}" variant="secondary" class="px-3" size="sm">Edit Customer</b-button>
              </b-skeleton-wrapper>
            </div>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col class="d-flex flex-row align-items-center justify-content-center" v-if="!isLoading">
            <b-button v-b-popover.hover.bottom="'Create an order for this customer'" class="mx-1 icon-button d-flex align-items-center justify-content-center" :to="{name: 'dash.orders.create', params: { customer_id: customer.id }}">
              <b-icon icon="cart-4"/>
            </b-button>
            <b-button v-if="customer.email" v-b-popover.hover.bottom="'Schedule an email'" class="mx-1 icon-button d-flex align-items-center justify-content-center" v-b-modal.email-modal>
              <b-icon icon="envelope"/>
            </b-button>
            <b-button v-b-popover.hover.bottom="'Set a reminder on this customer'" class="mx-1 icon-button d-flex align-items-center justify-content-center" :to="{name: 'dash.reminders.create', params: { customer_id: customer.id }}">
              <b-icon icon="list-task"/>
            </b-button>
            <b-button  v-b-popover.hover.bottom="'Open a ticket for this customer'" class="mx-1 icon-button d-flex align-items-center justify-content-center" :to="{name: 'dash.tickets.create', params: { customer_id: customer.id }}">
              <b-icon icon="chat-left-text"/>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col cols="12">
            <b-skeleton-wrapper key="customer-info-skeleton" :loading="isLoading">
              <template #loading>

                  <b-skeleton width="30%"></b-skeleton>
                  <b-skeleton width="90%"></b-skeleton>

                  <b-skeleton width="40%" class="mt-4"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>

                  <b-skeleton width="20%" class="mt-4"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>

                  <b-skeleton width="40%" class="mt-4"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>

                  <b-skeleton width="20%" class="mt-4"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>

                  <b-skeleton width="40%" class="mt-4"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
              </template>

              <b-form-group label="First Name" label-size="sm" class="right-panel-form-group">
                <b-form-input disabled v-model="customer.forename" size="sm"></b-form-input>
              </b-form-group>

              <b-form-group label="Surname" label-size="sm" class="right-panel-form-group">
                <b-form-input disabled v-model="customer.surname" size="sm"></b-form-input>
              </b-form-group>

              <b-form-group label="Email" label-size="sm" class="right-panel-form-group" v-if="customer.email">
                <b-form-input disabled v-model="customer.email" size="sm"></b-form-input>
              </b-form-group>

              <b-form-group label="Mobile" label-size="sm" class="right-panel-form-group" v-if="customer.mobile">
                <div @mouseover="focussedInput = 'input-fmobile'" @mouseleave="focussedInput = null">
                  <b-overlay :show="focussedInput == 'input-fmobile'">
                    <b-form-input disabled v-model="customer.mobile" size="sm"></b-form-input>
                    <template #overlay>
                      <small><a class="text-decoration-none" :href="`tel:${customer.mobile}`">Call Now</a></small>
                    </template>
                  </b-overlay>
                </div>
              </b-form-group>

              <b-form-group label="Second Mobile" label-size="sm" class="right-panel-form-group" v-if="customer.second_mobile">
                <div @mouseover="focussedInput = 'input-smobile'" @mouseleave="focussedInput = null">
                  <b-overlay :show="focussedInput == 'input-smobile'">
                    <b-form-input disabled v-model="customer.second_mobile" size="sm"></b-form-input>
                    <template #overlay>
                      <small><a class="text-decoration-none" :href="`tel:${customer.second_mobile}`">Call Now</a></small>
                    </template>
                  </b-overlay>
                </div>
              </b-form-group>

              <b-form-group label="Landline" label-size="sm" class="right-panel-form-group" v-if="customer.landline">
                <div @mouseover="focussedInput = 'input-landline'" @mouseleave="focussedInput = null">
                  <b-overlay :show="focussedInput == 'input-landline'">
                    <b-form-input disabled v-model="customer.landline" size="sm"></b-form-input>
                    <template #overlay>
                      <small><a class="text-decoration-none" :href="`tel:${customer.landline}`">Call Now</a></small>
                    </template>
                  </b-overlay>
                </div>
              </b-form-group>

            </b-skeleton-wrapper>

            <hr>

            <b-skeleton-wrapper key="customer-billing-skeleton" :loading="isLoading">
              <template #loading>

                  <b-skeleton width="30%"></b-skeleton>
                  <b-skeleton width="90%"></b-skeleton>

                  <b-skeleton width="40%" class="mt-4"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>

                  <b-skeleton width="20%" class="mt-4"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>

                  <b-skeleton width="40%" class="mt-4"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>

                  <b-skeleton width="20%" class="mt-4"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>

              </template>

              <b-form-group label="Billing Address Line 1" v-if="customer.billing_address.address_line_1" :class="{ 'active': focussedInput == 'input-baddr-1' }" label-size="sm" class="right-panel-form-group">
                <b-form-input disabled @focus="focussedInput = 'input-baddr-1'" @blur="focussedInput = null" v-model="customer.billing_address.address_line_1" size="sm"></b-form-input>
              </b-form-group>

              <b-form-group label="Billing Address Line 2" v-if="customer.billing_address.address_line_2" :class="{ 'active': focussedInput == 'input-baddr-2' }" label-size="sm" class="right-panel-form-group">
                <b-form-input disabled @focus="focussedInput = 'input-baddr-2'" @blur="focussedInput = null" v-model="customer.billing_address.address_line_2" size="sm"></b-form-input>
              </b-form-group>

              <b-form-group label="Billing City" v-if="customer.billing_address.city" :class="{ 'active': focussedInput == 'input-baddr-city' }" label-size="sm" class="right-panel-form-group">
                <b-form-input disabled @focus="focussedInput = 'input-baddr-city'" @blur="focussedInput = null" v-model="customer.billing_address.city" size="sm"></b-form-input>
              </b-form-group>

              <b-form-group label="Billing County" v-if="customer.billing_address.county" :class="{ 'active': focussedInput == 'input-baddr-county' }" label-size="sm" class="right-panel-form-group">
                <b-form-input disabled @focus="focussedInput = 'input-baddr-county'" @blur="focussedInput = null" v-model="customer.billing_address.county" size="sm"></b-form-input>
              </b-form-group>

              <b-form-group label="Billing Postcode" v-if="customer.billing_address.postcode" :class="{ 'active': focussedInput == 'input-baddr-postcode' }" label-size="sm" class="right-panel-form-group">
                <b-form-input disabled @focus="focussedInput = 'input-baddr-postcode'" @blur="focussedInput = null" v-model="customer.billing_address.postcode" size="sm"></b-form-input>
              </b-form-group>

            </b-skeleton-wrapper>

            <hr v-if="customer.shipping_address && customer.shipping_address.postcode">

            <b-skeleton-wrapper key="customer-shipping-skeleton" :loading="isLoading">
              <template #loading>

                  <b-skeleton width="30%"></b-skeleton>
                  <b-skeleton width="90%"></b-skeleton>

                  <b-skeleton width="40%" class="mt-4"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>

                  <b-skeleton width="20%" class="mt-4"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>

                  <b-skeleton width="40%" class="mt-4"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>

                  <b-skeleton width="20%" class="mt-4"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>

              </template>

              <h6 v-if="customer.shipping_address && customer.shipping_address.postcode">Shipping Details</h6>

              <b-form-group label="Address Line 1" v-if="customer.shipping_address.address_line_1" :class="{ 'active': focussedInput == 'input-saddr-1' }" label-size="sm" class="right-panel-form-group">
                <b-form-input disabled @focus="focussedInput = 'input-saddr-1'" @blur="focussedInput = null" v-model="customer.shipping_address.address_line_1" size="sm"></b-form-input>
              </b-form-group>

              <b-form-group label="Address Line 2" v-if="customer.shipping_address.address_line_2" :class="{ 'active': focussedInput == 'input-saddr-2' }" label-size="sm" class="right-panel-form-group">
                <b-form-input disabled @focus="focussedInput = 'input-saddr-2'" @blur="focussedInput = null" v-model="customer.shipping_address.address_line_2" size="sm"></b-form-input>
              </b-form-group>

              <b-form-group label="City" v-if="customer.shipping_address.city" :class="{ 'active': focussedInput == 'input-saddr-city' }" label-size="sm" class="right-panel-form-group">
                <b-form-input disabled @focus="focussedInput = 'input-saddr-city'" @blur="focussedInput = null" v-model="customer.shipping_address.city" size="sm"></b-form-input>
              </b-form-group>

              <b-form-group label="County" v-if="customer.shipping_address.county" :class="{ 'active': focussedInput == 'input-saddr-county' }" label-size="sm" class="right-panel-form-group">
                <b-form-input disabled @focus="focussedInput = 'input-saddr-county'" @blur="focussedInput = null" v-model="customer.shipping_address.county" size="sm"></b-form-input>
              </b-form-group>

              <b-form-group label="Postcode" v-if="customer.shipping_address.postcode" :class="{ 'active': focussedInput == 'input-saddr-postcode' }" label-size="sm" class="right-panel-form-group">
                <b-form-input disabled @focus="focussedInput = 'input-saddr-postcode'" @blur="focussedInput = null" v-model="customer.shipping_address.postcode" size="sm"></b-form-input>
              </b-form-group>

            </b-skeleton-wrapper>



          </b-col>
        </b-row>
      </b-col>

    </b-row>

  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import momentMixin from '../../mixins/momentMixin'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'
import FriendlyDate from '../../components/FriendlyDate';

import CustomerActivity from './CustomerViewComponents/CustomerActivity';
import CustomerNotes from './CustomerViewComponents/CustomerNotes';
import CustomerOrders from './CustomerViewComponents/CustomerOrders';
import CustomerSupport from './CustomerViewComponents/CustomerSupport';
import CustomerCorrespondence from './CustomerViewComponents/CustomerCorrespondence';
import CustomerReminders from './CustomerViewComponents/CustomerReminders';

export default {
  components: { FriendlyDate, CustomerActivity, CustomerNotes, CustomerOrders, CustomerSupport, CustomerCorrespondence, CustomerReminders },
  mixins: [validation, momentMixin, currentUser],
  created () {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters('customers', ['errors', 'isLoading', 'isSaving', 'single']),
  },
  data () {
    return {
      detailsOpen: false,
      focussedInput: null,
      tabIndex: null,

      customer: {
        products: [],
        billing_address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          county: '',
          postcode: '',
        },
        shipping_address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          county: '',
          postcode: '',
        },
        forename: null,
        surname: null,
        mobile: null,
        second_mobile: null,
        landline: null,
        email: null,
        emails: [],
        notes: [],
        calls: [],
        customer_enquiries: [],
        status: null,
        group: null,
        email_confirmation: null,
        user_id: null,
        user: { id: null },
      }
    }
  },
  methods: {
    ...mapActions('customers', ['fetch']),
  },
  watch: {
    '$route': function(from, to) {
      this.fetch(this.$route.params.id)
    },

    single () {
      if(!this.single.shipping_address) {
        this.single.shipping_address = {
          address_line_1: '',
          address_line_2: '',
          city: '',
          county: '',
          postcode: ''
        };
      }
      if(!this.single.billing_address) {
        this.single.billing_address = {
          address_line_1: '',
          address_line_2: '',
          city: '',
          county: '',
          postcode: ''
        };
      }

      if(this.single.user == null) {
        this.single.user = { id: null }
      }
      
      this.customer = this.single
    }
  },
}
</script>

<style lang="scss">

  
  .customer-view-tabs {
    .nav {
      flex-wrap: inherit;
      overflow: scroll;
      width: 100%;
      border-bottom: none;

      li:first-of-type .nav-link {
        border-left: none;
        border-top-left-radius: 0;
      }

      li:last-of-type .nav-link {
        border-right: none;
        border-top-right-radius: 0;
      }
    }
    .nav-justified .nav-item, .nav-justified>.nav-link {
        border-bottom: 1px solid #dee2e6;
    }
  }

  .tab-content {
    height: calc(100vh - 13.5rem) !important;
    overflow: scroll !important;
    background: white;
  }

  .right-panel {
    border-left: 1px solid #d8d8df;
    overflow: scroll;
    height: calc(100vh - 68px);

    .icon-button {
      border-radius: 100%;
      height: 40px;
      width: 40px;
      border: 2px solid #8f8fa3;
      background-color: $light;
      color: #8f8fa3;

      &:hover {
        color: $primary;
        border: 2px solid $primary;
      }
    }

    .right-panel-form-group {
      border: unset;
      border-radius: unset;
      background: unset;
      position: relative;

      .form-control {
        border: unset;
        border-radius: unset;
        background: unset;
        position: relative;
      }
    }
  }

  .negate-header-padding {
    height: calc(100vh - 68px);

    .col {
      min-height: 100%;
      max-height: 100%;
    } 
  }
  
  .cursor-pointer {
    cursor: pointer;
  }

  .no-btn-shadow {
    box-shadow: none!important;
  }

  #email-modal {
    .modal-header {
      padding-right: 0!important;
    }
  }

  .email-sidebar {

    .email-sidebar-scroll {
      overflow-y: scroll;
    }

    ul.nav {

      li.nav-header {
        color: lighten($dark, 20%);
        font-size: 0.8rem;
        font-weight: bold;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
      }

      li.nav-item {
        text-overflow: ellipsis;
        width: 100%;

        a {
          color: $dark;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;

          svg {
            color: lighten($dark, 10%);
            margin-top: -.2em;
          }

          &:not(.active):hover {
            background: darken($light, 5%);
          }

          &.active {
            background: $primary;
            color: $light;

            svg {
              color: $light;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    .right-panel {
      position: absolute;
      display: none;
    }
    .right-panel.open {
      display: block;
    }
  }
</style>
