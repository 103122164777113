<template>
    <b-row class="filter-container-parent">
        <b-col cols="12" class="filter-container">
            <b-row class="filter-row">
                <b-col cols="12" class="filter-col">
                    <small class="text-muted">Filter</small>
                    <b-form-select class="filter-select mt-2" v-model="filterSelect" @change="doFetch">
                        <b-form-select-option value="All">All</b-form-select-option>
                        <b-form-select-option value="Due Today">Due Today</b-form-select-option>
                        <b-form-select-option value="Overdue">Overdue</b-form-select-option>
                        <b-form-select-option value="Upcoming">Upcoming</b-form-select-option>
                        <b-form-select-option value="Complete">Complete</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12">
            <b-row>
                <b-col cols="12" v-if="customerReminders.isLoading" class="d-flex justify-content-center">
                    <b-spinner variant="secondary"></b-spinner>
                </b-col>
                <b-col v-if="!customerReminders.isLoading && !customerReminders.data.length">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no reminders.</p>
                          <b-button variant="outline-secondary" class="mt-3"  :to="{name: 'dash.reminders.create', params: { customer_id: customer }}">Set Reminder</b-button>
                        </b-card>
                      </b-col>
                    </b-row>
                </b-col>
                <b-col v-if="!customerReminders.isLoading && customerReminders.data.length">
                    <b-row v-for="(reminder, reminderInd) in customerReminders.data" :key="`reminder${reminderInd}`">
                        <b-col cols="12">
                            <b-card no-body class="mb-3">
                                <b-row>
                                    <b-col cols="12">
                                        <b-row class="p-3">
                                            <b-col cols="7" class="d-flex flex-column align-items-start justify-content-center">
                                                <p class="h5 flex-fill mb-1">{{ reminder.note }}</p>
                                                <small class="text-muted mb-1">{{ returnMomentDue(reminder).format('LLL') }}</small>
                                                <small class="text-muted">Reminder By: {{ reminder.user.name }}</small>
                                            </b-col>
                                            <b-col cols="5" class="d-flex align-items-start justify-content-end">
                                                <small v-if="reminder.completed_at" class="text-muted">Completed At: {{ moment(reminder.completed_at).format('DD/MM/YYYY') }}</small>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <template #footer v-if="reminder.customer_note">
                                    <b-row class="my-2">
                                        <b-col cols="12">
                                            <div class="d-flex flex-column">
                                                <h6 class="mb-0">Customer Note</h6>
                                                <div><hr class="my-2"></div>
                                                <span>{{ reminder.customer_note.note }}</span>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </template>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import validation from '../../../mixins/validation'
import FriendlyDate from '../../../components/FriendlyDate';
import momentMixin from '../../../mixins/momentMixin';

export default {
    mixins: [ validation, momentMixin ],
    components: { FriendlyDate },
    props: [ 'customer' ],
    data: () => ({
        filterSelect: 'All',
    }),
    mounted() {
        this.doFetch()
    },
    computed: {
        ...mapGetters('customers', ['customerReminders', 'errors'])
    },
    methods: {
        ...mapActions('customers', ['fetchCustomerReminders']),

        doFetch() {
            this.fetchCustomerReminders({
                customer: this.customer,
                filter: this.filterSelect,
            })
        },

        returnMomentDue(reminder) {
            return this.moment( reminder.date +' '+ reminder.time )
        },

    },

}

</script>

<style lang="scss">

    .filter-container-parent {
        isolation: isolate;

        .filter-container {
            position: sticky;
            top: 0;
            z-index: 1;

            .filter-row {
                position: sticky;
                margin-bottom: 20px;
                border-bottom: 1px solid #e6e7e9;
                
                .filter-col {
                    background-color: #f8fafd;
                    padding-bottom: 20px;
                    padding-top: 10px;
                }

                .filter-select {
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    appearance: none;
                    background: #f8fafd url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234BB5A2' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
                }
            }
        }
    }

</style>