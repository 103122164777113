<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body class="p-3 mb-3">
                <b-row>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="7" class="d-flex flex-column align-items-start justify-content-center">
                                <p class="h5 flex-fill mb-1"><fa-icon icon="phone" class="mr-2"/>{{ data.type | capitalise }} <small class="small">({{ data.customer_identifier.contact_number }})</small></p>
                                <small class="text-muted" v-if="data.handled_by_identifier && !data.handled_by_identifier.missed">Handled By: {{ data.handled_by_identifier.identifier }}</small>
                                <small v-else class="text-muted">Missed Call</small>
                            </b-col>
                            <b-col cols="5" class="d-flex align-items-start justify-content-end">
                                <FriendlyDate :date="data.date_time"/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import FriendlyDate from '../../../../components/FriendlyDate';
import momentMixin from '../../../../mixins/momentMixin'

export default {
    props: [ 'data' ],
    components: { FriendlyDate },
    mixins: [ momentMixin ],
    filters: {
        capitalise(v) {
            if(!v) return 'Error.'
            return String(v).charAt(0).toUpperCase() + String(v).slice(1)
        }
    }
}
</script>