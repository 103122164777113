<template>
    <b-card class="mb-3">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12" md="7" class="d-flex flex-row align-items-center">
                        <div class="mt-3 ml-3 mb-0 mb-md-3">
                            <h5>{{ data.subject }}</h5>
                            <h6>From: <small>{{ data.from_email }}</small></h6>
                            <h6>To: <small>{{ data.to_email }}</small></h6>
                        </div>
                    </b-col>
                    <b-col cols="12" md="5" class="d-flex flex-column flex-fill align-items-start justify-content-start align-items-md-end w-100 max-width">
                        <div class="ml-3 mr-3 mt-0 mt-md-3">
                            <small class="text-right"><span class="text-muted">{{ moment(data.date).format('DD/MM/YYYY @ HH:mm') }}</span></small>
                        </div>
                        <b-button @click="e => ( $emit('replyingToEmail', data.id) )" variant="link" class="mr-3 mr-md-0 no-btn-shadow">Reply</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <div class="m-3"><hr></div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <div class="m-3">
                            <iframe ref="iframe" :srcdoc="data.body" frameborder="0" class="w-100"></iframe>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="data.attachments && data.attachments.length">
                    <b-col cols="12">
                        <div class="mb-3 ml-3 mr-3">
                            <p>Attachments:</p>
                            <div class="d-flex flex-row flex-fill flex-wrap">
                                <a target="_blank" :href="`/attachment/${attachment.id}`" class="btn btn-outline-secondary mr-2 m2" v-for="(attachment, attachmentInd) in data.attachments" :key="attachmentInd"><fa-icon icon="download" /> {{ attachment.name }}</a>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import FriendlyDate from '../../../../components/FriendlyDate';
import momentMixin from '../../../../mixins/momentMixin'

export default {
    props: [ 'data' ],
    components: { FriendlyDate },
    mixins: [ momentMixin ],
    mounted() {
        // this.resizeIFrames()
    },
    methods: {
        // resizeIFrames() {
        //     setTimeout(() => {
        //         this.$refs[`iframe`].height = (this.$refs[`iframe`].contentWindow.document.body.clientHeight + 20) + "px"
        //     }, 750)
        // }
    }
}

</script>