<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body class="p-3 mb-3">
                <b-row>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="7" class="d-flex flex-column align-items-start justify-content-center">
                                <p class="h5 flex-fill mb-1">
                                    <fa-icon icon="globe" class="mr-2"/>
                                    <a :href="data.full_url" target="_blank" class="text-break">{{ data.full_url | prettyUrl }}</a>
                                </p>
                                <small class="text-muted" v-if="data.source">Source: {{ data.source.name }}</small>
                            </b-col>
                            <b-col cols="5" class="d-flex align-items-start justify-content-end">
                                <FriendlyDate :date="data.created_at"/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import FriendlyDate from '../../../../components/FriendlyDate';
import momentMixin from '../../../../mixins/momentMixin'

export default {
    props: [ 'data' ],
    components: { FriendlyDate },
    mixins: [ momentMixin ],
    filters: {
        prettyUrl(str) {
            if (str.indexOf("?") > 0) {
                str = str.substring(0, str.indexOf("?"));
            }
            str = str.replace('https://betterlivingoutdoors.com', '');
            return str;
        }
    }
}
</script>