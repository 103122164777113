<template>
    <b-row class="mb-3">
        <b-col cols="12">
            <b-card no-body class="p-3">
                <b-row>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="7" class="d-flex flex-column align-items-start justify-content-center">
                                <p class="h5 mb-0 flex-fill mb-0 text-primary"><router-link class="text-dark" :to="{name: 'dash.tickets.view', params: { id: data.id }}"><fa-icon icon="comment-alt-lines" class="mr-2"/>Ticket #{{ data.id }} <small class="text-muted">({{ data.spa }})</small></router-link></p>
                                <small class="text-muted mb-0">{{ data.status }}</small>
                            </b-col>
                            <b-col cols="5" class="d-flex align-items-start justify-content-end">
                                <FriendlyDate :date="data.created_at"/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FriendlyDate from '../../../../components/FriendlyDate';
import momentMixin from '../../../../mixins/momentMixin';

export default {
    mixins: [ momentMixin ],
    components: { FriendlyDate },
    props: [ 'data' ],
    filters: {
        currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
            style: 'currency', currency: 'GBP'
        }).format(v),
    }
}

</script>