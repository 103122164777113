<template>
    <b-modal id="email-modal" centered vertical size="lg">
      <template #modal-header="{ cancel }">
        <div class="container-fluid mt-3 mb-3">
          <b-row>
            <b-col cols="7" class="flex flex-column flex-fill align-items-center justify-content-center">
              <h4>Schedule {{ replyingToEmail ? 'Reply to ' : '' }}Email</h4>
              <small>To: {{ customer.forename }} {{ customer.surname }} ({{ customer.email }})</small>
            </b-col>
            <b-col cols="5" class="d-flex flex-row flex-fill align-items-start justify-content-end">
              <b-button variant="danger" @click="cancel"><fa-icon icon="times" /></b-button>
            </b-col>
          </b-row>
        </div>
      </template>

      <template #default>
        <div class="container-fluid">
          <b-row>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'subject')">
                <b-input placeholder="Subject" v-model="newEmailSubject" :state="validationState(errors.errors, 'subject')"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex flex-row justify-content-between">
                      <div class="d-flex flex-column justify-content-center">
                        <p class="h3 font-weight-bold text-muted mb-0">Schedule Email</p>
                      </div>
                      <div class="d-flex flex-column flex-lg-row justify-content-end align-items-center w-50">
                        <b-form-checkbox v-model="setEmailSchedule"></b-form-checkbox>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="setEmailSchedule">
                  <b-col cols="12">
                    <hr>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group class="mb-0" :invalid-feedback="validationInvalidFeedback(errors.errors, 'scheduled_date')">
                      <b-form-datepicker placeholder="Scheduled Date" v-model="newEmailScheduledDateAt" :state="validationState(errors.errors, 'scheduled_date')"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group class="mb-0" :invalid-feedback="validationInvalidFeedback(errors.errors, 'scheduled_time')">
                      <b-input placeholder="Scheduled Time (HH:MM)" v-model="newEmailScheduledTimeAt" :state="validationState(errors.errors, 'scheduled_time')"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-row justify-content-between">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="h3 font-weight-bold text-muted mb-0">Template</p>
                  </div>
                  <div class="d-flex flex-column flex-lg-row justify-content-center mt-3 mt-md-0">
                    <template-search
                      :error-feed="errors.errors"
                      :error-key="'template_id'"
                      class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100"
                      type="template"
                      @input="templateUpdated"
                      v-model="template"
                    />
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <Quill :new-content="templateContent" v-model="newEmailContent"/>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" role="button">
                    <div class="container-fluid">
                      <b-row>
                        <b-col md="7" class="d-flex flex-row" @click.prevent="toggleCollapse('email_attachments')">
                          <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['email_attachments'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                          <div class="d-flex flex-column flex-fill ml-4 justify-content-center">
                            <p class="h4 font-weight-bold mb-0">Attachments</p>
                          </div>
                        </b-col>
                        <b-col md="5" class="d-flex flex-row align-items-center justify-md-content-end pt-3 pt-md-0 pr-0">
                          <b-button variant="outline-secondary" :disabled="attachmentButtonClicked" @click="newFileUpload"><fa-icon icon="plus" /> Add Attachment</b-button>
                          <b-form-file v-show="false" @change="collapsed['email_attachments'] = false" autofocus v-for="(attachment, attachmentInd) in newEmailAttachments" v-model="newEmailAttachments[attachmentInd]" :key="attachmentInd" :id="`attachment-upload${attachmentInd}`"></b-form-file>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['email_attachments']">
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="12">
                        <hr class="mt-0">
                      </b-col>
                    </b-row>
                    <b-row v-if="filteredNewEmailAttachments.length">
                      <b-col cols="12" class="">
                        <div class="d-flex flex-row flex-fill flex-wrap">
                          <span v-for="(attachment, attachmentInd) in newEmailAttachments" :key="`email-attachment${attachmentInd}`" v-show="attachment != null"><b-button :variant="hoveredAttachment == attachmentInd ? 'outline-danger' : 'outline-primary'" @mouseover="hoveredAttachment = attachmentInd" @mouseleave="hoveredAttachment = null" @click="rmAttachment(attachmentInd)" class="mr-2 mb-2" v-if="attachment != null"><fa-icon :icon="hoveredAttachment == attachmentInd ? 'times' : 'file-import'" class="mr-2"/>{{ attachment.name }}</b-button></span>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row v-if="!filteredNewEmailAttachments.length">
                      <b-col cols="12" class="d-flex justify-content-center">
                        <p class="mt-1 mb-0">There are no attachments currently selected.</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex flex-row justify-content-between">
                      <div class="d-flex flex-column justify-content-center">
                        <p class="h3 font-weight-bold text-muted mb-0">Set Reminder</p>
                      </div>
                      <div class="d-flex flex-column flex-lg-row justify-content-end align-items-center w-50">
                        <b-form-checkbox v-model="setEmailReminder"></b-form-checkbox>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="setEmailReminder">
                  <b-col cols="12">
                    <hr>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group class="mb-0" :invalid-feedback="validationInvalidFeedback(errors.errors, 'reminder.date')">
                      <b-form-datepicker placeholder="Reminder Date" v-model="reminder.date" :state="validationState(errors.errors, 'reminder.date')"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6" class="mt-3 mt-md-0 md-0">
                    <b-form-group class="mb-0" :invalid-feedback="validationInvalidFeedback(errors.errors, 'reminder.time')">
                      <b-input placeholder="Reminder Time (HH:MM)" :state="validationState(errors.errors, 'reminder.time')" v-model="reminder.time"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="mt-3">
                    <b-form-group class="mb-0" label="Note" :invalid-feedback="validationInvalidFeedback(errors.errors, 'reminder.note')">
                      <b-form-textarea v-model="reminder.note" :state="validationState(errors.errors, 'reminder.note')"></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </template>

      <template #modal-footer>
        <b-button @click="queueEmail()"><fa-icon icon="paper-plane" /> Schedule</b-button>
      </template>
    </b-modal>

</template>

<script>
    import momentMixin from '../../../../mixins/momentMixin'
    import validation from '../../../../mixins/validation'
    import Quill from '../../../../components/Quill';
    import { mapActions, mapGetters } from 'vuex'
    import TemplateSearch from '../../../../components/TemplateSearch';

    export default {
        mixins: [ momentMixin, validation ],
        props: [ 'customer', 'replyingToEmail' ],
        components: { Quill, TemplateSearch },
        mounted() {
            this.newEmailScheduledDateAt = this.moment().format('YYYY-MM-DD')
            this.newEmailScheduledTimeAt = this.moment().format('HH:mm')

            this.$root.$on('bv::modal::hide', (bvModal, modalId) => {
                if(modalId == 'email-modal') {
                    this.newEmailSubject = null
                    this.newEmailContent = null
                    this.newEmailScheduledDateAt = this.moment().format('YYYY-MM-DD')
                    this.newEmailScheduledTimeAt = this.moment().format('HH:mm')
                    this.newEmailAttachments = []
                    this.attachmentButtonClicked = false
                    this.hoveredAttachment = null
                    this.template = { id: null }
                    this.templateContent = null

                    this.clearErrors()
                }
            })

        },
        data: () => ({
            collapsed: {
                email_attachments: true,
            },

            templateContent: null,

            template: { id: null },

            newEmailSubject: null,
            newEmailContent: null,
            newEmailScheduledDateAt: null,
            newEmailScheduledTimeAt: null,
            newEmailAttachments: [],
            attachmentButtonClicked: false,
            hoveredAttachment: null,
            setEmailSchedule: false,

            setEmailReminder: false,

            reminder: {
                note: null,
                date: null,
                time: null,
            },
        }),
        computed: {
            ...mapGetters('customers', [ 'errors' ]),
            filteredNewEmailAttachments() {
                return this.newEmailAttachments.filter(attachment => {
                    return (attachment != null)
                })
            },
        },
        methods: {
            ...mapActions('customers', [ 'doQueueEmail', 'clearErrors' ]),
            queueEmail() {
                let data = new FormData()

                data.append('subject', this.newEmailSubject)
                data.append('content', this.newEmailContent)
                data.append('scheduled_date', !this.setEmailSchedule ? this.moment().format('YYYY-MM-DD') : this.newEmailScheduledDateAt)
                data.append('scheduled_time', !this.setEmailSchedule ? this.moment().format('HH:mm') : this.newEmailScheduledTimeAt)
                data.append('replying_to', this.replyingToEmail ?? '')

                if(this.setEmailReminder) {
                    data.append('reminder[note]', this.reminder.note)
                    data.append('reminder[date]', this.reminder.date)
                    data.append('reminder[time]', this.reminder.time)
                }

                this.newEmailAttachments.forEach((attachment, attachmentInd) => {
                    if(attachment != null) data.append(`attachments[${attachmentInd}]`, attachment)
                })

                this.doQueueEmail({ 'customer': this.customer.id, data})
                    .then(response => {
                        this.$root.$emit('bv::hide::modal', 'email-modal')
                        this.$root.$emit('card-notification', {
                          variant: 'success',
                          title: `Email Queued`,
                          subtitle: `The email has been added to the queue and will appear once it has been dispatched.`,
                          timeout: 3000
                        })
                    })
            },

            newFileUpload() {
                this.attachmentButtonClicked = true
                this.newEmailAttachments.push(null)
                this.$nextTick().then(() => {
                    document.getElementById(`attachment-upload${this.newEmailAttachments.length-1}`).click()
                    setTimeout(() => {
                        this.attachmentButtonClicked = false
                    }, 500)
                })
            },

            rmAttachment(attachmentInd) {
                this.newEmailAttachments.splice(attachmentInd, 1)
                this.newEmailAttachments = this.newEmailAttachments.filter(attachment => {
                    return (attachment != null)
                })
            },

            templateUpdated() {
                this.templateContent = this.template.delta_content
            },

            toggleCollapse (name) {
                this.collapsed[name] = !this.collapsed[name]
            },
        },
        watch: {
            replyingToEmail() {
                if(this.replyingToEmail) this.$root.$emit('bv::show::modal', 'email-modal')
            }
        }
    }

</script>