<template>
    <b-row class="mb-3">
        <b-col cols="12">
            <b-card no-body class="p-3">
                <b-row>
                    <b-col cols="8" class="d-flex flex-column align-items-start justify-content-center">
                        <h5 class="mb-0"><fa-icon icon="headset" class="mr-2"/>Live Chat Session</h5>
                    </b-col>
                    <b-col cols="4" class="d-flex align-items-start justify-content-end">
                        <p class="mb-0"><FriendlyDate :direction="'right'" :date="data.created_at"/></p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <hr>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" v-for="(message, messageInd) in data.messages" :key="`liveChatMessage${messageInd}`">
                        <div class="chat-message" :class="{ 'visitor-message' : message.user_type === 'visitor', 'agent-message' : message.user_type === 'agent' }">
                        <h5 class="mb-1">{{ message.author_name }}</h5>
                        <p>{{ message.text }}</p>
                        <small>{{ moment(message.sent_at).format('DD/MM/YYYY @ HH:mm') }}</small>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import FriendlyDate from '../../../../components/FriendlyDate';
import momentMixin from '../../../../mixins/momentMixin';

export default {
    mixins: [ momentMixin ],
    components: { FriendlyDate },
    props: [ 'data' ],
}

</script>

<style lang="scss">

    .chat-message {
        padding: 1rem;
        margin-bottom: 1rem;
        border-radius: 0.5rem;

        &.agent-message {
            background: #4BB5A2;
            color: #fff;
            margin-left: 20%;
        }
        &.visitor-message {
            background: #2e3145;
            color: #fff;
            margin-right: 20%;
        }
    }

</style>
