<template>
    <b-row>
        <b-col cols="12" v-if="customerSupport.isLoading" class="d-flex justify-content-center"><b-spinner variant="secondary"></b-spinner></b-col>
        <b-col v-if="!customerSupport.isLoading && !customerSupport.data.length">
            <b-row>
                <b-col class="text-center" cols="12">
                    <b-card class="py-3 text-center">
                        <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                        <p class="font-weight-normal h5 mb-0 text-dark">There are no tickets.</p>
                        <b-button variant="outline-secondary" class="mt-3"  :to="{name: 'dash.tickets.create', params: { customer_id: customer }}">Create Ticket</b-button>
                    </b-card>
                </b-col>
            </b-row>
        </b-col>
        <b-col v-if="!customerSupport.isLoading && customerSupport.data.length">
            <b-row v-for="(ticket, ticketInd) in customerSupport.data" :key="`ticket${ticketInd}`">
                <b-col cols="12">
                    <b-card no-body class="p-3" :class="{ 'mb-1': Boolean( (customerSupport.data.length - 1) != ticketInd ) }">
                        <b-row>
                            <b-col cols="12">
                                <b-row>
                                    <b-col cols="7" class="d-flex flex-column align-items-start justify-content-center">
                                        <p class="h5 mb-0 flex-fill mb-0 text-primary"><router-link class="text-dark" :to="{name: 'dash.tickets.view', params: { id: ticket.id }}">Ticket #{{ ticket.id }} <small class="text-muted">({{ ticket.spa }})</small></router-link></p>
                                        <small class="text-muted mb-0">{{ ticket.status }}</small>
                                    </b-col>
                                    <b-col cols="5" class="d-flex align-items-start justify-content-end">
                                        <FriendlyDate :date="ticket.created_at"/>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import validation from '../../../mixins/validation'
import momentMixin from '../../../mixins/momentMixin'
import FriendlyDate from '../../../components/FriendlyDate';

export default {
    mixins: [ validation, momentMixin ],
    components: { FriendlyDate },
    props: ['customer'],
    mounted() {
        this.doFetch()
    },
    computed: {
        ...mapGetters('customers', ['customerSupport', 'errors'])
    },
    methods: {
        ...mapActions('customers', ['fetchCustomerSupport']),

        doFetch() {
            this.fetchCustomerSupport(this.customer)
        },
    },
}

</script>