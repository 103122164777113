<template>
    <b-row class="scrollable">
        <b-col cols="12" v-if="customerCorrespondence.isLoading" class="d-flex justify-content-center"><b-spinner variant="secondary"></b-spinner></b-col>
        <b-col v-if="!customerCorrespondence.isLoading && !customerCorrespondence.data.length">
            <b-row>
                <b-col class="text-center" cols="12">
                    <b-card class="py-3 text-center">
                        <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                        <p class="font-weight-normal h5 mb-0 text-dark">There has been no correspondence.</p>
                        <b-button variant="outline-secondary" class="mt-3" v-b-modal.email-modal>Schedule Email</b-button>
                    </b-card>
                </b-col>
            </b-row>
        </b-col>
        <b-col v-if="!customerCorrespondence.isLoading && customerCorrespondence.data.length">
            <b-row v-for="(message, messageInd) in customerCorrespondence.data" :key="messageInd">
                <b-col cols="12">
                    <b-card class="mb-3">
                        <b-row>
                            <b-col cols="12" v-if="message.type == 'dispatched-email'">
                                <b-row>
                                    <b-col cols="12" md="7" class="d-flex flex-row align-items-center">
                                        <div class="mt-3 ml-3 mb-0 mb-md-3">
                                            <h5 v-if="message.subject">{{ message.subject }}</h5>
                                            <h5 v-if="!message.subject">{{ message.type }}</h5>
                                            <small class="text-small text-muted" v-if="message.read_at">Read At: {{ moment(message.read_at).format('DD/MM/YYYY') }}<br></small>
                                            <small class="text-small text-muted" v-if="message.read_count">Read {{ message.read_count == 1 ? 'once' : message.read_count + ' times' }}<br></small>
                                            <small class="text-muted">Email</small>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="5" class="d-flex flex-column flex-fill align-items-start justify-content-start align-items-md-end w-100 max-width">
                                        <div class="ml-3 mr-3 mt-0 mt-md-3">
                                            <small class="text-right"><span class="text-muted">{{ moment(message.date).format('DD/MM/YYYY @ HH:mm') }}</span></small>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <div class="m-3"><hr></div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <div class="m-3" v-html="message.content"></div>
                                    </b-col>
                                </b-row>
                            </b-col>



                            <b-col cols="12" v-else>
                                <b-row>
                                    <b-col cols="12" md="7" class="d-flex flex-row align-items-center">
                                        <div class="mt-3 ml-3 mb-0 mb-md-3">
                                            <h5>{{ message.subject }}</h5>
                                            <h6>From: <small>{{ message.row.from_email }}</small></h6>
                                            <h6>To: <small>{{ message.row.to_email }}</small></h6>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="5" class="d-flex flex-column flex-fill align-items-start justify-content-start align-items-md-end w-100 max-width">
                                        <div class="ml-3 mr-3 mt-0 mt-md-3">
                                            <small class="text-right"><span class="text-muted">{{ moment(message.date).format('DD/MM/YYYY @ HH:mm') }}</span></small>
                                        </div>
                                        <b-button @click="replyingToEmail = message.row.id" variant="link" class="mr-3 mr-md-0 no-btn-shadow">Reply</b-button>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <div class="m-3"><hr></div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <div class="m-3">
                                            <iframe :ref="`iframe${message.row.id}`" v-if="!customerCorrespondence.isLoading" :srcdoc="message.content" frameborder="0" class="w-100"></iframe>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row v-if="message.row.attachments && message.row.attachments.length">
                                    <b-col cols="12">
                                        <div class="mb-3 ml-3 mr-3">
                                            <p>Attachments:</p>
                                            <div class="d-flex flex-row flex-fill flex-wrap">
                                                <a target="_blank" :href="`/attachment/${attachment.id}`" class="btn btn-outline-secondary mr-2 m2" v-for="(attachment, attachmentInd) in message.row.attachments" :key="attachmentInd"><fa-icon icon="download" /> {{ attachment.name }}</a>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>

                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-col>

    </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import validation from '../../../mixins/validation'
import momentMixin from '../../../mixins/momentMixin'
import FriendlyDate from '../../../components/FriendlyDate';
import TemplateSearch from '../../../components/TemplateSearch';

export default {
    mixins: [ validation, momentMixin ],
    components: { FriendlyDate, TemplateSearch },
    props: ['customer', 'tabIndex'],
    mounted() {
        this.newEmailScheduledDateAt = this.moment().format('YYYY-MM-DD')
        this.newEmailScheduledTimeAt = this.moment().format('HH:mm')
        window.addEventListener('resize', event => { 
            if (typeof this.resizeIFrames === "function") { 
                this.resizeIFrames();
            }
        })
        this.$root.$on('bv::modal::hide', (bvModal, modalId) => {
            if(modalId == 'email-modal') {
                this.replyingToEmail = null
                this.newEmailSubject = null
                this.newEmailContent = null
                this.newEmailScheduledDateAt = this.moment().format('YYYY-MM-DD')
                this.newEmailScheduledTimeAt = this.moment().format('HH:mm')
                this.newEmailAttachments = []
                this.attachmentButtonClicked = false
                this.hoveredAttachment = null
                this.template = { id: null }
                this.templateContent = null
            }
        })
    },
    beforeDestroy() {

    },
    data: () => ({
        collapsed: {
            email_attachments: true,
        },

        templateContent: null,

        template: { id: null },

        replyingToEmail: null,
        newEmailSubject: null,
        newEmailContent: null,
        newEmailScheduledDateAt: null,
        newEmailScheduledTimeAt: null,
        newEmailAttachments: [],
        attachmentButtonClicked: false,
        hoveredAttachment: null,
        setEmailSchedule: false,

        setEmailReminder: false,

        reminder: {
            note: null,
            date: null,
            time: null,
        },
    }),
    computed: {
        ...mapGetters('customers', ['customerCorrespondence', 'errors']),
        filteredNewEmailAttachments() {
            return this.newEmailAttachments.filter(attachment => {
                return (attachment != null)
            })
        },
    },
    methods: {
        ...mapActions('customers', ['fetchCustomerCorrespondence', 'doQueueEmail']),

        doFetch() {
            this.fetchCustomerCorrespondence(this.customer.id)
                .then(() => { this.resizeIFrames() })
        },

        queueEmail() {
            let data = new FormData()

            data.append('subject', this.newEmailSubject)
            data.append('content', this.newEmailContent)
            data.append('scheduled_date', !this.setEmailSchedule ? this.moment().format('YYYY-MM-DD') : this.newEmailScheduledDateAt)
            data.append('scheduled_time', !this.setEmailSchedule ? this.moment().format('HH:mm') : this.newEmailScheduledTimeAt)
            data.append('replying_to', this.replyingToEmail ?? '')

            if(this.setEmailReminder) {
                data.append('reminder[note]', this.reminder.note)
                data.append('reminder[date]', this.reminder.date)
                data.append('reminder[time]', this.reminder.time)
            }

            this.newEmailAttachments.forEach((attachment, attachmentInd) => {
                if(attachment != null) data.append(`attachments[${attachmentInd}]`, attachment)
            })

            this.doQueueEmail({ 'customer': this.customer.id, data})
              .then(response => {
                this.$root.$emit('card-notification', {
                  variant: 'success',
                  title: `Email Queued`,
                  subtitle: `The email has been added to the queue and will appear once it has been dispatched.`,
                  timeout: 3000
                })
              })
        },

        newFileUpload() {
            this.attachmentButtonClicked = true
            this.newEmailAttachments.push(null)
            this.$nextTick().then(() => {
                document.getElementById(`attachment-upload${this.newEmailAttachments.length-1}`).click()
                setTimeout(() => {
                    this.attachmentButtonClicked = false
                }, 500)
            })
        },

        rmAttachment(attachmentInd) {
            this.newEmailAttachments.splice(attachmentInd, 1)
            this.newEmailAttachments = this.newEmailAttachments.filter(attachment => {
                return (attachment != null)
            })
        },

        templateUpdated() {
            this.templateContent = this.template.delta_content
        },

        resizeIFrames() {
            setTimeout(() => {
                if(this.customerCorrespondence.data.length) {
                    this.customerCorrespondence.data.forEach(email => {
                        if(email.type == 'imap-email') {
                            if(this.$refs[`iframe${email.row.id}`] && this.$refs[`iframe${email.row.id}`][0]) {
                                if(this.$refs[`iframe${email.row.id}`][0].contentWindow.document.body) {
                                    this.$refs[`iframe${email.row.id}`][0].height = (this.$refs[`iframe${email.row.id}`][0].contentWindow.document.body.clientHeight + 20) + "px"
                                }
                            }
                        }
                    })
                }
            }, 250)
        },


        attachmentIsHovered(attachmentInd) {
            return (attachmentInd == this.hoveredAttachment)
        },
    },
    watch: {
        replyingToEmail() {
            if(this.replyingToEmail) this.$root.$emit('replyingToEmail', this.replyingToEmail)
        },

        customer() {
            this.doFetch()
            this.resizeIFrames()
        },

        tabIndex() {
          this.resizeIFrames()
        }
    }
}

</script>