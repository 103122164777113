<template>
    <b-row>
        <b-col cols="12">
            <b-row>
                <b-col cols="12">
                    <b-form-group label="Add a Note" class="mb-2" :invalid-feedback="validationInvalidFeedback(errors.errors, 'newNote')">
                        <b-form-textarea id="note-input" :state="validationState(errors.errors, 'newNote')" v-model="newNote" placeholder="Insert note here..." size="sm"></b-form-textarea>
                        <template #description>
                            <b-form-checkbox class="mt-2" switch v-model="reminder.create" variant="secondary" size="sm">Create Reminder</b-form-checkbox>
                        </template>
                    </b-form-group>
                    <b-row v-if="reminder.create">
                        <b-col cols="12">
                            <hr class="mt-0">
                        </b-col>
                        <b-col cols="12">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group label="Reminder Date" :invalid-feedback="validationInvalidFeedback(errors.errors, 'date')">
                                        <b-form-datepicker :state="validationState(errors.errors, 'date')" v-model="reminder.date" />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group label="Reminder Time" :invalid-feedback="validationInvalidFeedback(errors.errors, 'time')">
                                        <b-form-timepicker :disabled="isSaving" :state="validationState(errors.errors, 'time')" v-model="reminder.time" />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Reminder Note" :invalid-feedback="validationInvalidFeedback(errors.errors, 'note')">
                                <b-form-textarea :disabled="isSaving" :state="validationState(errors.errors, 'note')" v-model="reminder.note" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="d-flex flex-row flex-fill">
                        <b-button variant="primary" block @click="addNote">Add Note</b-button>
                    </div>
                </b-col>
                <b-col cols="12"><hr></b-col>
                <b-col cols="12" v-if="customerNotes.isLoading" class="d-flex justify-content-center">
                    <b-spinner variant="secondary"></b-spinner>
                </b-col>
                <b-col v-if="!customerNotes.isLoading && !customerNotes.data.length">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no notes.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                </b-col>
                <b-col v-if="!customerNotes.isLoading && customerNotes.data.length">
                    <b-row v-for="(note, noteInd) in customerNotes.data" :key="noteInd" class="mt-3">
                        <b-col cols="12" :invalid-feedback="validationInvalidFeedback(errors.errors, 'note.' + noteInd + '.note')">
                            <b-card no-body>
                                <b-row class="p-4">
                                    <b-col cols="12">
                                        <b-row>
                                            <b-col cols="11">
                                                <p class="white-space-pre mb-0">{{ note.note }}</p>
                                            </b-col>
                                            <b-col cols="1" class="d-flex flex-row align-items-start justify-content-end">
                                                <b-button variant="danger" @click="rmNote(noteInd)" size="sm"><fa-icon :icon="'trash'"/></b-button>
                                            </b-col>
                                        </b-row>
                                        <hr />
                                        <b-row>
                                            <b-col cols="9" class="d-flex flex-row justify-content-start align-items-center">
                                                <p class="note-created mb-0">Left by: {{ note.user.name }}</p>
                                            </b-col>
                                            <b-col cols="3" class="d-flex flex-row justify-content-end align-items-center">
                                                <p class="note-created text-muted mb-0"><FriendlyDate :date="note.created_at"></FriendlyDate></p>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <template #footer v-if="note.reminder">
                                    <b-row class="my-2">
                                        <b-col cols="12">
                                            <div class="d-flex flex-row">
                                                <div class="d-flex flex-column flex-fill">
                                                    <h6 class="mb-1">Reminder</h6>
                                                    <small class="text-muted">Due: {{ moment(note.reminder.date).format('DD/MM/YYYY') }} @ {{ moment(note.reminder.time, 'HH:mm:ss').format('HH:mm') }}</small>
                                                </div>
                                                <span>{{ note.reminder.completed_at ? 'Complete' : 'Pending' }}</span>
                                            </div>
                                            <hr class="mt-2">
                                            <span>{{ note.reminder.note }}</span>
                                        </b-col>
                                    </b-row>
                                </template>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import validation from '../../../mixins/validation'
import momentMixin from '../../../mixins/momentMixin'
import FriendlyDate from '../../../components/FriendlyDate';

export default {
    mixins: [ validation, momentMixin ],
    components: { FriendlyDate },
    props: [ 'customer' ],
    mounted() {
        this.doFetch()
    },
    data: () => ({
        newNote: null,

        reminder: {
            create: false,
            date: null,
            time: null,
            note: null
        }
    }),
    computed: {
        ...mapGetters('customers', ['customerNotes', 'errors'])
    },
    methods: {
        ...mapActions('customers', ['fetchCustomerNotes']),

        doFetch() {
            this.fetchCustomerNotes(this.customer)
        },

        rmNote(ind) {
            if(this.customerNotes.data[ind].id) {
                window.axios.post(`/customers/${this.customer}/note/delete/${this.customerNotes.data[ind].id}`)
                    .then(response => {
                        if(response.data) this.doFetch()
                    })
            }
        },

        addNote() {
            if(this.newNote) {
                window.axios.post(`/customers/${this.customer}/note/add`, {
                    note: this.newNote,
                    reminder: this.reminder
                }).then( response => {
                    if(response.data) {
                        this.newNote = null;
                        this.doFetch()
                    }
                })
            }
        },

    },

}

</script>