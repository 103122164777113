<template>
    <b-row>
        <b-col cols="12" v-if="customerOrders.isLoading" class="d-flex justify-content-center"><b-spinner variant="secondary"></b-spinner></b-col>
        <b-col v-if="!customerOrders.isLoading && !customerOrders.data.length">
            <b-row>
                <b-col class="text-center" cols="12">
                    <b-card class="py-3 text-center">
                        <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                        <p class="font-weight-normal h5 mb-0 text-dark">There are no orders.</p>
                        <b-button variant="outline-secondary" class="mt-3"  :to="{name: 'dash.orders.create', params: { customer_id: customer }}">Create Order</b-button>
                    </b-card>
                </b-col>
            </b-row>
        </b-col>
        <b-col v-if="!customerOrders.isLoading && customerOrders.data.length">
            <b-row v-for="(order, orderInd) in customerOrders.data" :key="`order${orderInd}`">
                <b-col cols="12">
                    <b-card no-body class="mb-3 p-3">
                        <b-row>
                            <b-col cols="12">
                                <b-row>
                                    <b-col cols="6" class="d-flex flex-column align-items-start justify-content-center">
                                        <p class="h5 mb-0"><router-link class="text-dark" v-b-popover.hover.left="'View Order.'" :to="{name: 'dash.orders.view', params: { id: order.id }}">Order #{{ order.id }}</router-link></p>
                                        <small class="mb-0 text-muted">Handled By: {{ order.user.name }}</small>
                                    </b-col>
                                    <b-col cols="6" class="d-flex align-items-start justify-content-end">
                                        <p class="mb-0"><FriendlyDate :direction="'right'" :date="order.created_at"/></p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12"><hr></b-col>
                                </b-row>
                                <b-row v-if="order.finance_package_id" class="mt-2 mb-2">
                                    <b-col cols="12">
                                        <div>
                                            <b-badge sm variant="primary">Finance</b-badge>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row v-if="order.additional_notes" class="mt-2 mb-2">
                                    <b-col cols="12">
                                        <div class="d-flex flex-column">
                                        <small>Additional Notes:</small>
                                        <small class="text-muted white-space-pre">{{ order.additional_notes }}</small>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <h6 class="mb-2">Order Items:</h6>
                                        <b-card no-body class="p-3" v-for="item in order.items" :key="`orderItem${item.id}`">
                                            <b-row>
                                                <b-col cols="12">
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-row flex-fill">
                                                            <div class="flex-column flex-fill">
                                                                <p class="mb-0 flex-fill">{{ item.product.name }}</p>
                                                                <div class="flex-row">
                                                                <b-badge variant="danger" class="mr-2" v-if="item.install_required" v-b-popover.hover.left="item.install_at ? 'Scheduled: ' + moment(item.install_at).format('DD/MM/YYYY') : 'Not Scheduled.'" pill>Installation Required</b-badge>
                                                                <b-badge variant="danger" class="mr-2" v-if="item.crane_required" pill>Crane Required</b-badge>
                                                                <b-badge variant="danger" class="mr-2" v-if="item.electrics_required" pill>Electrics Required</b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="flex-column text-right" v-if="item.supply_product">
                                                                <p class="mb-0 text-muted">Expected {{ item.supply_product.expected_date ? moment(item.supply_product.expected_date).format('DD/MM/YYYY') : moment(item.supply_product.supply.expected_date).format('DD/MM/YYYY') }}</p>
                                                                <small class="mb-0 text-muted">{{ item.supply_product.supply.name }}</small>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex flex-column mt-3" v-if="item.extras.length">
                                                            <b-card>
                                                                <h6 class="mb-3">Extras:</h6>
                                                                <b-table-simple small striped>
                                                                    <b-thead>
                                                                        <b-tr>
                                                                            <b-th>Name</b-th>
                                                                            <b-th class="text-right"><span class="mr-2">Amount</span></b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr v-for="(extra, extraInd) in item.extras" :key="`extra${item.id}-${extraInd}`">
                                                                            <b-td>{{ extra.name }}</b-td>
                                                                            <b-td class="text-right"><span class="mr-2">{{ extra.price | currencyFormat }}</span></b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-card>
                                                        </div>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12"><hr></b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" class="d-flex flex-fill align-items-end justify-content-end">
                                        <b-button variant="secondary" class="mr-1" size="sm" :to="{name: 'dash.invoices.create', params: { order_id: order.id }}" v-if="!order.cancelled_at && (order.remaining_amount > 0)">Raise Invoice</b-button>
                                        <b-button variant="info" class="mr-1" size="sm" @click.prevent v-b-popover.hover.bottom="'The Total Remaing Amount to be paid.'">{{ order.remaining_amount | currencyFormat }}</b-button>
                                        <b-button variant="secondary" class="mr-1" size="sm" @click.prevent v-b-popover.hover.bottom="'The total of unpaid invoices.'">{{ order.invoices_pending_total | currencyFormat }}</b-button>
                                        <b-button variant="primary" size="sm" @click.prevent v-b-popover.hover.bottom="'The total of invoices paid.'">{{ order.invoices_paid_total | currencyFormat }}</b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import validation from '../../../mixins/validation'
import momentMixin from '../../../mixins/momentMixin'
import FriendlyDate from '../../../components/FriendlyDate';

export default {
    mixins: [ validation, momentMixin ],
    components: { FriendlyDate },
    props: ['customer'],
    mounted() {
        this.doFetch()
    },
    computed: {
        ...mapGetters('customers', ['customerOrders', 'errors'])
    },
    methods: {
        ...mapActions('customers', ['fetchCustomerOrders']),

        doFetch() {
            this.fetchCustomerOrders(this.customer)
        },
    },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }

}

</script>